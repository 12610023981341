import styles from './Home.module.css'
import rp_discord_print from '../images/rp_discord_print.png'
import rp_camp from '../images/rp_camp.png'
import rp_news from '../images/rp_news.png'
import rp_about from '../images/rp_about.png'
import planet_logo from '../images/planet_logo410.png'


function Home() {
    window.scrollTo(0, 0);
    return (
        <main>
            <section className={styles.space}>
                <article className={styles.head}>
                    <img src={planet_logo} alt="Logo Planeta"/>
                    <h1>Um Planeta como Você nunca Viu</h1>
                </article>
            </section>
            <section className={styles.page}>
                <section className={styles.headline}>
                    <article className={styles.intro}>
                        <h2>Experimente o melhor espaço além do virtual ao se juntar ao nosso servidor Discord!</h2>
                    </article>
                </section>
                <section className={styles.cards}>
                    <a href='./' className={styles.card_pages}>
                        <img src={rp_discord_print} alt="Imagem do Servidor Discord do R Passa" />
                        <h1>Discord</h1>
                    </a>
                    <a href='./' className={styles.card_pages}>
                        <img src={rp_camp} alt="Imagem do Servidor Discord do R Passa" />
                        <h1>Eventos</h1>
                    </a>
                    <a href='https://discord.com/channels/809935545752617000/1233619344928411648' className={styles.card_pages}>
                        <img src={rp_news} alt="Imagem do Servidor Discord do R Passa" />
                        <h1>Novidades</h1>
                    </a>
                    <a href='/sobre' className={styles.card_pages}>
                        <img src={rp_about} alt="Imagem do Servidor Discord do R Passa" />
                        <h1>Sobre</h1>
                    </a>
                </section>
            </section>
        </main>
    )
}

export default Home