import styles from './Terms.module.css'

function Terms() {
    window.scrollTo(0, 0);
    return (
        <main>
            <section className={styles.space}>
                <article className={styles.head}>
                    <h1>Termos & Condições</h1>
                    <p>R Passa Discord</p>
                </article>
            </section>
            <section className={styles.page}>
                <article>
                    <h2>Introdução e Aceitação dos Termos</h2>
                    <p><b>Bem-vindo (ou vinda) ao R Passa!</b></p>
                    <p>
                        Estes Termos de Serviço ("Termos"), que incluem e, neste ato, incorporam a Política de Privacidade em <a href="/privacidade">Privacy Policy</a> ("Política de Privacidade"), são um contrato jurídico entre a R Passa mais suas coligadas (a "Equipe", "nós", "nosso(a)" e "conosco") e você ("você"). Ao usar ou acessar o R Passa através do aplicativo Discord (o “Aplicativo”) ou o site encontrado em <a href="https://discord.com">https://discord.com</a> (o “Site”), em conjunto chamados de “Serviço”, você confirma:
                        <ol>
                            <li>que tem mais de 12 anos de idade ou a idade mínima de consentimento digital em seu país;</li>
                            <li>que, se tiver atingido a maioridade conforme as leis de seu local de residência, leu, compreendeu e aceitou se vincular aos Termos;</li>
                            <li>que se tiver entre 13 (ou a idade mínima de consentimento digital, conforme aplicável) e o número correspondente à maioridade conforme as leis de seu local de residência, seus pais ou tutores analisaram e aceitaram estes Termos.</li>
                        </ol>
                        A Equipe se reserva o direito de atualizar estes Termos, o que poderá ocorrer por motivos que incluem, dentre outros, conformidade a eventuais alterações legislativas ou para refletir aprimoramentos feitos ao R Passa. Caso isso afete seu uso do R Passa ou seus direitos, informaremos o fato a você pelo menos sete dias antes de sua entrada em vigor. A menos que haja estipulação em contrário, a continuação do uso do Serviço por você após a publicação das modificações constituirá sua aceitação de tais alterações. Caso se oponha às alterações, seu recurso será deixar de utilizar o Serviço.
                    </p>
                    <section className={styles.update}>
                        <p><b>Última atualização e validação:</b></p><p> 06 de Maio de 2023</p>
                    </section>
                </article>
                <article>
                    <h2>Regras de Conduta e Uso</h2>
                    <p>O Serviço oferece canais de comunicação, como fóruns, comunidades ou áreas de chat ("Canais de Comunicação") criados para permitir que você se comunique com outros usuários do Serviço. Como condição de seu uso do Serviço, e sem limitação de suas outras obrigações consoante estes Termos, você concorda em obedecer às restrições e regras de uso estabelecidas nestes Termos e em nossas Diretrizes da comunidade, bem como a outras restrições e regras (como regras específicas do aplicativo) definidas no Serviço. Por exemplo, você aceita não utilizar o Serviço para:</p>
                    <ul>
                        <li>Respeite igualmente todos que estão no Discord, exceto o Rhamoun;
                            <ul><li>Caso venha a acontecer o contrário, denuncie para algum Administrador ou Moderador.</li></ul>
                        </li>
                        <li>Proibido fazer o cadastro com dados falsos (Exemplo: Clicar em Turista sendo que vc é da Etec Cotia);</li>
                        <li>Proibido o uso de imagem do usuário humble570 (Originalmente HumbleGalaxy570#5056) sem devida autorização;</li>
                        <li>Não pode usar comandos não amigáveis com os STAFFs que não quiserem;</li>
                        <li>Proibido Spam, Flood e XP Farming Alone nos Chats de Conversa;
                            <ul><li>XP Farming Alone: Falar consigo mesmo.</li></ul>
                        </li>
                        <li>Proibido burlar ou tentar burlar regras e punições;</li>
                        <li>Proibido ameaças consideradas não amigáveis;</li>
                        <li>Proibido mandar links de outros Servidores que possam afetar a integridade deste;</li>
                        <li>Proibido mensagens ou imagem que tenha uma palavrões ou conotação sexual. No caso dos palavrões, ele pode, se usar o "/spoiler" antes da mensagem para que a mensagem seja censurada;
                            <ul><li>Isso inclui qualquer NSFW através de um avatar, imagem, vídeo, emoji ou link.</li></ul>
                        </li>
                        <li>Proibido a difamação do servidor ou dos STAFFs; </li>
                        <li>Proibido o incentivo ao uso de drogas e entorpecentes;</li>
                        <li>Proibido apologia e estímulo do racismo, xenofobia, inferiorização de raça, religião, condição econômica ou opção sexual;</li>
                        <li>Não se passe por STAFF, seja colocando o nick e foto de algum ou falando que é da mesma;</li>
                        <li>Não deixe os Bots sozinhos dentro das Calls;</li>
                        <li>Proibido usar foto de perfil com a imagem de qualquer membro sem possuir autorização;</li>
                        <li>Proibido contas fakes ou secundárias (existem exceções);</li>
                        <li>Proibido enviar coisas ofensivas por qualquer chat privado ou público para qualquer membro.</li>
                    </ul>
                    <section className={styles.update}>
                        <p><b>Última atualização e validação:</b></p><p> 06 de Maio de 2023</p>
                    </section>
                </article>
                <article>
                    <h2>Regulamentos dos Sorteios R Passa</h2>
                    <ol>
                        <li>Introdução: <p>Os Sorteios R Passa são um programa de recompensas desenvolvido pela Equipe R Passa e oferecido aos seus usuários. Este programa, de vigência por prazo indeterminado, permite aos usuários participar de sorteios gratuitamente, nos quais a sorte determinará os ganhadores dos prêmios.</p></li>
                        <li>Prazo para Recebimento do Prêmio: <p>A Equipe R Passa se compromete a disponibilizar o prêmio ao vencedor no prazo de até 30 (trinta) dias úteis.</p></li>
                        <li>Alterações e Disponibilidade de Prêmios: <p>A Equipe R Passa reserva-se o direito de alterar, retirar ou incluir novas recompensas nos sorteios a qualquer momento, conforme a disponibilidade dos prêmios.</p></li>
                        <li>Elegibilidade: <p>A participação é válida exclusivamente para usuários residentes no Brasil, devidamente identificados e com cadastro atualizado para o ano de 2024 na guia Canais & Cargos. A participação está sujeita aos Termos e Condições do R Passa Discord, disponíveis em https://rpassa.com.br/termos.</p></li>
                        <li>Aceitação dos Termos e Condições: <p>Ao participar dos Sorteios R Passa, o usuário declara que leu, compreendeu e aceita todas as regras e condições estabelecidas neste regulamento.</p></li>
                    </ol>
                    <section className={styles.update}>
                        <p><b>Última atualização e validação:</b></p><p> 06 de Maio de 2023</p>
                    </section>
                </article>
                
            </section>
        </main>
    )
}

export default Terms;
