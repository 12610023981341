import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './pages/Home'
import About from './pages/About'
import Terms from './pages/Terms'
import Policy from './pages/Policy'
import ExternalRedirect from './components/ExternalRedirect'

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/sobre' element={<About />} />
          <Route path='/termos' element={<Terms />} />
          <Route path='/politica' element={<Policy />} />
          <Route path='/privacidade' element={<Policy />} />
          <Route path='/pesquisa2024' element={<ExternalRedirect to="https://forms.gle/ZBhmVjmH2DSfdznN8" />} />
          <Route path="/rafael" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
