import styles from './Header.module.css'
import rpassa_ws_logo from '../../images/rpassa_white_shadow_logo.png'
import Navbar from './Navbar'


function Header() {
    return (
        <>
            <header>
                <section className={styles.head}>
                    <a href="./" className={styles.link}><img src={rpassa_ws_logo} alt="Logo do R Passa" className={styles.image_logo}/></a>
                    <Navbar />
                </section>
            </header>
        </>
    )
}

export default Header