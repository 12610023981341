import styles from './Policy.module.css'

function Policy() {
    window.scrollTo(0, 0);
    return (
        <main>
            <section className={styles.space}>
                <article className={styles.head}>
                    <h1>Política de Privacidade</h1>
                    <p>R Passa Discord</p>
                </article>
            </section>
            <section className={styles.page}>
                <article>
                    <h2>R Passa</h2>
                    <p>
                        A R Passa oferece uma plataforma social online e de chat móvel por meio do Discord (o "Site"), do aplicativo Discord (o "Aplicativo") e de serviços de Internet associados (conjuntamente denominados "Serviço(s)"). O Serviço é operado pela R Passa (a "Companhia", "nós", "nosso(a)" ou "conosco") para usuários do Serviço ("você"). Esta Política de Privacidade define nossa política relativa às informações que são coletadas de visitantes ou usuários do Servidor e/ou dos Serviços. Nos termos da legislação aplicável, a R Passa é a "controladora de dados" dos dados pessoais coletados pelos Serviços.
                    </p>
                </article>
                <article>
                    <h2>Informações que Coletamos</h2>
                    <p>
                        <b>Quando você interage conosco pelos Serviços, podemos coletar informações sobre você conforme descrito abaixo:</b><br/>
                        <b>Informações fornecidas por você:</b> Coletamos informações suas quando você as fornece voluntariamente, por exemplo, ao se registrar para ter acesso aos Serviços ou para utilizar determinados Serviços. As informações coletadas podem incluir, dentre outras, nome de usuário, endereço de email, mensagens, imagens, dados de VOIP transitórios (somente para permitir a entrega da comunicação) e conteúdo enviado pelo recurso de chat.<br/>
                        <b>Informações fornecidas por terceiros:</b> Coletamos informações suas quando terceiros associados nos repassam para identificação. As informações coletadas podem incluir, dentre outras, Data de Nascimento, Número de Telefone, Endereço e dados disponibilizados pelo governo do país a que pertence.<br/>
                    </p>
                    <section className={styles.update}>
                        <p><b>Última atualização e validação:</b></p><p> 06 de Maio de 2023</p>
                    </section>
                </article>

            </section>
        </main>
    )
}

export default Policy;