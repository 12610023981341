import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import MediaQuery from 'react-responsive'
import styles from './Navbar.module.css'
import MenuButton from './MenuButton'
import CloseButton from './CloseButton'

function Navbar(){
    const [isNavOpen, setIsNavOpen] = useState(false);
    const toggleNavbar = () => {
        setIsNavOpen(prevState => !prevState);
    };
    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path ? {color: 'var(--brandeisBlue)'} : null;
        
    }

    return(
        <>
            <nav className={isNavOpen ? `${styles.nav} ${styles.responsive_nav}` : styles.nav}>
                <article>
                    <Link to="/" style={isActive('/')} onClick={toggleNavbar}>Home</Link>
                    <Link to="https://rpassaingressos.suareservaonline.com.br">Ingressos</Link>
                    <Link to="https://www.instagram.com/rpassa_alunos">Instagram</Link>
                    <Link to="https://discord.com/channels/809935545752617000/1233619344928411648">Novidades</Link>
                    <Link to="/sobre" style={isActive('/sobre')} onClick={toggleNavbar}>Sobre</Link>
                </article>
                <MediaQuery maxWidth={767}>
                    <button className={`${styles.nav_btn} ${styles.nav_close_btn}`} onClick={toggleNavbar}>
                        <CloseButton />
                    </button>
                </MediaQuery>
            </nav>
            <MediaQuery maxWidth={767}>
                <button className={styles.nav_btn} onClick={toggleNavbar}>
                    <MenuButton />
                </button>
            </MediaQuery>
        </>
    )
}

export default Navbar