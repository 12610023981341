import styles from './About.module.css'
import saturn_logo from '../images/saturn_logo410.png'
import timeline from '../images/timeline_desktop.png'
import adm from '../images/adm.png'
import luiz from '../images/luiz.png'

function About() {
    window.scrollTo(0, 0);
    return (
        <main>
            <section className={styles.space}>
                <article className={styles.head}>
                    <img src={saturn_logo} alt="Logo Saturno" />
                    <h1>Nós somos o R Passa</h1>
                </article>
            </section>
            <section className={`${styles.about} ${styles.bg_white}`}>
                <article className={`${styles.intro}`}>
                    <p>Bem-vindo ao R Passa, onde forjamos conexões, criamos memórias e inspiramos a imaginação. Junte-se a nós nesta jornada e explore novos horizontes do universo. Nós somos o R Passa, onde a excelência e a diversão se encontram.</p>
                </article>
            </section>
            <section>
                <article className={`${styles.history}`}>
                    <h2>Nossa História</h2>
                    <p>&emsp;Idealizado por Rafael Pinheiro em meio à complexidade e desafios da Pandemia do Covid-19, no dia 12 de Fevereiro de 2021 foi criado o R Passa Discord. No entanto, foi no dia seguinte, graças à extroversão do Luiz Nascimento, que o servidor foi oficialmente revelado ao mundo, cativando imediatamente a atenção de 10 novos usuários ávidos por uma nova forma de interação.</p>
                    <p>&emsp;Inspirado pela missão de fortalecer os laços em meio ao distanciamento, o R Passa começou sua jornada como uma resposta à necessidade urgente de melhorar a comunicação na turma do 2º ETIM de 2021 da Etec de Cotia. O sucesso inicial foi apenas o prelúdio para uma narrativa que se desdobraria em uma trajetória de crescimento e expansão, ultrapassando os limites originais das salas de aula e acolhendo diversas facetas da Etec de Cotia.</p>
                    <p>&emsp;À medida que o R Passa ganhava popularidade, a entrada de indivíduos notáveis marcou uma virada significativa. Entre eles, destaca-se Guilherme, cuja contribuição, personificada pelo Bot Mudae, teve um impacto avassalador, transformando-se em um fenômeno amplamente utilizado para a criação de coleções de personagens, dando ao servidor uma dimensão criativa única.</p>
                    <p>&emsp;No início de 2022, após superar desafios e um colapso momentâneo, o R Passa abriu suas portas de maneira mais abrangente. Agora, estudantes além das fronteiras da Etec de Cotia eram bem-vindos, embora com um toque de formalidade: a identificação pela equipe da Monarquia Absolutista Democrática do R Passa tornou-se imperativa, garantindo uma comunidade acolhedora e segura.</p>
                    <p>&emsp;À medida que o tempo avançava, o R Passa evoluiu, desenvolvendo uma estrutura distintiva. Hoje, divide-se entre membros comuns e membros do clube, com estes últimos assumindo um papel central na dinâmica da comunidade. Uma mudança significativa ocorreu em 16 de Agosto de 2023, quando o R Passa expandiu suas operações para além do digital, transformando-se na renomada empresa de turismo, R Passa Ingressos, abrindo portas para novas aventuras e experiências além do universo virtual. Assim, o R Passa transcende seu status inicial de servidor, consolidando-se como um fenômeno multifacetado, onde a inovação, a comunidade e a exploração convergem em uma narrativa em constante evolução. </p>
                </article>
            </section>
            <section className={`${styles.about} ${styles.bg_white}`}>
                <h2 className={`${styles.about_us}`}>Sobre o que Somos</h2>
                <article className={`${styles.cards_about}`}>
                    <div className={`${styles.card_about} ${styles.card1}`}>
                        <h3>Comprometimento</h3>
                        <p>Nossa prioridade é assegurar um ambiente seguro e protegido, implementando sistemas robustos que garantam a segurança e a satisfação de cada usuário.</p>
                    </div>
                    <div className={`${styles.card_about} ${styles.card2}`}>
                        <h3>Criatividade</h3>
                        <p>Constantemente empenhados em elevar o nível de nossas criações, apresentamos eventos exclusivos, sorteios emocionantes e um entretenimento singular. Nossa criatividade é um farol que guia o Servidor R Passa para além das expectativas convencionais.</p>
                    </div>
                    <div className={`${styles.card_about} ${styles.card3}`}>
                        <h3>Comunidade</h3>
                        <p>No coração do R Passa reside uma comunidade acolhedora e colaborativa. Estamos empenhados em criar uma atmosfera onde cada membro se sinta valorizado e conectado, promovendo um ambiente de camaradagem e aprendizado mútuo.</p>
                    </div>
                </article>
            </section>
            <section className={`${styles.about} ${styles.bg_blue} ${styles.founders}`}>
                <h2>Conheça os Fundadores</h2>
                <section className={styles.cards_founders}>
                    <article>
                        <img src={adm} alt="" className={styles.card1} />
                        <h3>Rafael Pinheiro</h3>
                        <p>Fundador - Designer</p>
                    </article>
                    <article>
                        <img src={luiz} alt="" className={styles.card1} />
                        <h3>Luiz Nascimento</h3>
                        <p>Divulgador</p>
                    </article>
                </section>
            </section>
            <section className={`${styles.about} ${styles.bg_white} ${styles.timeline}`}>
                <h2 className={`${styles.about_us}`}>Linha do Tempo</h2>
                <img src={timeline} alt="Infográfico da Linha do Tempo R Passa" />
            </section>
        </main>
    )
}

export default About