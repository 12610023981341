import styles from './MenuButton.module.css'
import { HiMiniBars3} from "react-icons/hi2"

function MenuButton() {
    return (
        <div className={styles.menu_button}>
                <HiMiniBars3 className={styles.menu_icon} />
        </div>
    )
}

export default MenuButton