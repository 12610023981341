import { HiXMark } from 'react-icons/hi2'
import styles from './CloseButton.module.css'

function CloseButton() {
    return (
        <div className={styles.menu_button}>
            <HiXMark className={styles.menu_icon} />
        </div>
    )
}

export default CloseButton