import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import { FaTiktok, FaInstagram, FaYoutube, FaTwitch } from "react-icons/fa6"
import rpassa_w_logo from '../../images/rp_white_logo.png'

function Footer() {
    return (
        <footer className={styles.footer}>
            <section className={styles.socialMedia}>
                <a href="https://www.tiktok.com/@rpassa_alunos"><FaTiktok /></a>
                <a href="https://www.instagram.com/rpassa_alunos"><FaInstagram /></a>
                <a href="https://www.youtube.com/c/hg570"><FaYoutube /></a>
                <a href="https://www.twitch.tv/hg570"><FaTwitch /></a>
            </section>
            <section className={styles.container}>
                <article>
                    <ul>
                        <p>Navegação</p>
                        <li><Link to="https://rpassaingressos.suareservaonline.com.br">Ingressos</Link></li>
                        <li><Link to="https://www.instagram.com/rpassa_alunos">Instagram</Link></li>
                        <li><Link to="https://discord.com/channels/809935545752617000/1233619344928411648">Novidades</Link></li>
                        <li><Link to="/sobre">Sobre</Link></li>
                        <p></p>
                    </ul>
                </article>
                <article className={styles.side2}>
                    <ul>
                        <p>Política</p>
                        <li><Link to="/termos">Termos & Condições</Link></li>
                        <li><Link to="/privacidade">Política de Privacidade</Link></li>
                    </ul>
                </article>
            </section>
            <section className={styles.footerLogo}>
                <a href="./"><img src={rpassa_w_logo} alt="Logo Branca do R Passa" /></a>
            </section>
        </footer>
    )
}

export default Footer